import React, { useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from '../components/Header';
import step1Image from '../images/step_1.webp';
import step2Image from '../images/step_2.webp';
import step3Image from '../images/step_3.webp';

import { QRCodeSVG } from 'qrcode.react';
import { useSelector, useDispatch } from 'react-redux';
import { setShopName } from '../redux/shopSlice';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const steps = ['アプリダウンロード'
                , 'アカウント登録'
                , 'ポイントカード発行'
              ];

export default function CardIssuance({user}) {
  const userId = user.uid;
  const shopName = useSelector((state) => state.shop.shopName);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      const shopDocRef = doc(db, 'customers', userId);
      const docSnap = await getDoc(shopDocRef);

      if (docSnap.exists()) {
        // shop_nameのみを取得してstoreにセット
        const shopName = docSnap.data().shop_name;
        if (shopName) {
          dispatch(setShopName(shopName));
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error('Firestoreデータ取得エラー', error);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    // shopNameが空文字列の場合にのみ、fetchDataを実行
    if (shopName === "") {
      fetchData();
    }
  }, [fetchData, shopName]);


  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const stepImages = [step1Image, step2Image, step3Image];
  const currentImage = stepImages[activeStep];
  const qrData = {
    shop_id: user.uid,
    shop_name: shopName
  }
  const qrValue = JSON.stringify(qrData);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);

  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Header text={'ポイントカード発行'}/>

      <Stepper activeStep={activeStep} sx={{mt: 2}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
              <StepLabel {...labelProps}></StepLabel>
            </Step>
          );
        })}
      </Stepper>
      
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 4, mb: 3, fontSize: '20px', fontWeight: 'bold' }}>
            お店のQRコードはこちら
          </Typography>
          <Box sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
            {/* <QRCodeSVG value={user.uid} />  */}
            <QRCodeSVG value={qrValue} /> 
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>最初から</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
            <img src={currentImage} alt={`Step ${activeStep + 1}`} style={{ maxWidth: '90%', height: 'auto' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              戻る
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                スキップ
              </Button>
            )} */}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'お店のQRコードを表示' : '次へ'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}