import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddCardIcon from '@mui/icons-material/AddCard';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import StoreIcon from '@mui/icons-material/Store';
import BookOnlineIcon from '@mui/icons-material/BookOnline';

import Header from '../components/Header';


function Menu() {
  return (
    <div className="Menu">
      <Header text={'メニュー'}/>
      <Box sx={{ width: '100%', mt: 5 }}>
        <Stack spacing={2} alignItems="center">
          <MenuButton startIcon={<AddCardIcon />} label='ポイントカード発行' link='/cardIssuance'/>
          <MenuButton startIcon={<LocalParkingIcon />} label='ポイント付与/使用' link='/pointReading'/>
          <MenuButton startIcon={<StoreIcon />} label='店舗情報管理' link='/shopInfo'/>
          <MenuButton startIcon={<BookOnlineIcon />} label='クーポン管理' link='/couponList'/>
        </Stack>
      </Box>
    </div>
  );
}
export default Menu;

// 関数内の共通コンポーネント
const MenuButton = ({ startIcon, label, link }) => {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      component={Link}
      to={link}
      sx={{
        width: '60%'
        , height: 72
        , justifyContent: 'flex-start'
        , fontWeight: 'bold'
      }}
    >
      {label}
    </Button>
  );
};
