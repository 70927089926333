import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import Header from '../components/Header';
import { auth, db } from '../firebase';
import { updateDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth'; 
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setShopName, setPointRate } from '../redux/shopSlice';


const ShopNew = () => {
  const [user] = useAuthState(auth);
  const shopName = useSelector((state) => state.shop.shopName);
  const pointRate = useSelector((state) => state.shop.pointRate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNameChange = (event) => {
    dispatch(setShopName(event.target.value));
  };

  const handlePointRateChange = (event) => {
    dispatch(setPointRate(event.target.value));
  };

  const handleSave = async () => {

    // 入力チェック
    if (!shopName) {
      alert('店舗名を入力してください。');
      return;
    }

    try {
      const userRef = doc(db, 'customers', user.uid);
      await updateDoc(userRef, {
        shop_id: user.uid,
        shop_name: shopName,
        point_rate: pointRate,
        img_url: null,
      });
      // checkout
      navigate('/plan');
      
      // Payment Links
      // window.location.href = 'https://buy.stripe.com/test_3cscQ58XBeKI7HW000'

    } catch (error) {
      console.error('エラー', error);
    }
    //必要に応じて、フォームを初期化するなどの処理を追加
  };

  return (
    <div>
        <Header text={'店舗情報入力'} />
        <Box>
            <TextField
            label="店舗名"
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={shopName}
            onChange={handleNameChange}
            />
        </Box>
        <Box>
            <TextField
            label="ポイント付与率"
            fullWidth
            variant="outlined"
            margin="normal"
            required
            inputProps={{
              type: 'number',
              min: 0,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            value={pointRate}
            onChange={handlePointRateChange}
            />
        </Box>
        <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSave}>
                次へ
            </Button>
        </Box>
    </div>
  );
};

export default ShopNew;
