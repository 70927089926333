import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import Header from '../components/Header';

import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const NoticeList = () => {
  const [notices, setNotices] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = (notice) => {
    navigate(`/noticeDetail/${notice.id}`, { state: { notice: notice, formattedDate: notice.date?.toDate().toLocaleDateString('ja-JP') } });
  };

  useEffect(() => {
    const fetchNotices = async () => {
      // infoコレクションへの参照を作成
      const querySnapshot = await getDocs(collection(db, 'info'));
      // Firestoreから取得したデータをstateにセット
      const noticesArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotices(noticesArray);
    };
    fetchNotices();
  }, []);

  return (
    <div>
      <Header text={'お知らせ'}/>
      <List>
        {notices.map((notice) => (
          <ListItem key={notice.id} onClick={() => handleNavigate(notice)} divider>
            <ListItemText primary={notice.title} secondary={notice.date?.toDate().toLocaleDateString('ja-JP')} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default NoticeList;
