import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth'; 
import { auth, db } from '../firebase';
import { getDocs, query, where, collection, onSnapshot, addDoc, Timestamp } from 'firebase/firestore';

import { CircularProgress, Button, Box } from '@mui/material';

import Header from '../components/Header';


function Plan() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // プラン一覧を取得
      const productsCollection = collection(db, 'products');
      const q = query(productsCollection, where('active', '==', true));
      const querySnapshot = await getDocs(q);

      // 取得したプラン一覧から1プランずつ金額などの情報を取得
      const productData = [];

      // プラン一覧をから金額情報を取得
      const promises = querySnapshot.docs.map(async (doc) => {
        const priceCollection = collection(doc.ref, 'prices');
        const priceQuery = query(priceCollection, where('active', '==', true));
        const priceSnapshot = await getDocs(priceQuery);
        
        const product = doc.data();
        const prices = {};

        priceSnapshot.forEach((priceDoc) => {
          const priceId = priceDoc.id;
          const priceData = priceDoc.data();
          prices[priceId] = priceData;
        });
        
        productData.push({
          id: doc.id,
          product,
          prices,
        });
      });
      
      // forEachやmapでは非同期の処理を待たないため、for...ofかPromis.allで処理を待つ
      await Promise.all(promises);

      // useStateにプラン情報をセット
      setProducts(productData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // ローディング終了
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }
  
  return (
    // TODO:レイアウト修正　→　Width100％で中央表示にする　→　なぜか100％にならない
    <div>
      <Header text={'プラン選択'}/>
      <div className="products" style={{ marginTop: '0'}}>
        {products.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </div>
    </div>
  );

}

export default Plan;


function ProductItem({ product }) {
  const { product: productData, prices } = product;
  const [user] = useAuthState(auth);

  // TODO:複数プランから選択可能にする場合は以下の対応をする
  // 選択したプランの情報をstateで管理する
  // 選択したプランのpricesから対象のドキュメントを取得する
  // 例）product.prices.price_1LgjKWFSs4DMiXAdzEJc4pwI
  // その値をselectedPriceに入れる

  const subscribe = async (product) => {
    // 現在の日時を取得
    const now = new Date();

    // トライアル開始時刻（現在時刻）
    // const trialStartTimestamp = Math.floor(now.getTime() / 1000);

    // タイムスタンプを使用するパターン
    const trialStartTimestamp = Timestamp.fromDate(now); 

    // 30日加算
    const thirtyDaysLater = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);

    // Unixタイムスタンプに変換 (秒単位)
    const trialEndTimestamp = Math.floor(thirtyDaysLater.getTime() / 1000);
    // タイムスタンプを使用するパターン
    // const trialEndTimestamp = Timestamp.fromDate(thirtyDaysLater);


    const selectedPrice = {
      // 今はプランが1つのため、固定値で入れている
      // テスト用プラン
      // price: 'price_1LgjKWFSs4DMiXAdzEJc4pwI',
      // TODO:stripeの開発者画面から、「APIシークレット」と「webhook署名シークレット」を取得して、firebaseの拡張機能に登録し、商品を追加すると、firestoreが更新されて、商品を選択可能になる
      // 本番用プラン
      price: 'price_1PBVvcFSs4DMiXAdaeWG0GIY',
      quantity: 1,
    };
    
    const checkoutSession = {
      allow_promotion_codes: true,
      automatic_tax: true,
      cancel_url: `${window.location.origin}/plan`,
      collect_shipping_address: true,
      line_items: [selectedPrice],
      metadata: {
        key: 'value',
      },
      mode: 'subscription',
      success_url: window.location.origin,
      tax_id_collection: true,
      subscription_data: {
        items: [
          {
            // price: 'price_1LgjKWFSs4DMiXAdzEJc4pwI'
            price: 'price_1PBVvcFSs4DMiXAdaeWG0GIY'
          }
        ],
        trial_period_days: 30
      },
    };

    const docRef = await addDoc(collection(db, 'customers', user.uid, 'checkout_sessions'), checkoutSession);
    
    onSnapshot(docRef, (snap) => {

      const { error, url } = snap.data();

      if (error) {
        alert(`An error occurred: ${error.message}`);
        document.querySelectorAll('button').forEach((b) => (b.disabled = false));
      }
      
      if (url) {
        window.location.assign(url);
      }

    });

  };

  return (
    <div
      className="product"
      style={{
        display: 'flex'
        , flexDirection: 'column'
        , alignItems: 'center'
        , justifyContent: 'center'
        , textAlign: 'center'
        , padding: '16px'
        // , border: '1px solid #ddd'
        // , borderRadius: '8px'
        , width: '100%'
        , margin: '0'
      }}
    >

      <h2>{productData.name.toUpperCase()}</h2>

      <p className="description">{productData.description?.toUpperCase() || ''}</p>

      <select id="price">
        {Object.entries(prices).map(([priceId, priceData]) => (
          <option key={priceId} value={priceId}>
            {new Intl.NumberFormat('ja-JP', {
              style: 'currency',
              currency: 'JPY',
            }).format(priceData.unit_amount.toFixed(2))}
            (月額)
          </option>
        ))}
      </select>

      {productData.images.length > 0 && (
        <img src={productData.images[0]} alt={productData.name} />
      )}

      {/* <button onClick={() => subscribe(product)}>
        次へ
      </button> */}
      <Box mt={2}>
          <Button variant="contained" color="primary" onClick={() => subscribe(product)}>
              次へ
          </Button>
      </Box>

    </div>
  );

}


