import { configureStore } from "@reduxjs/toolkit";
import sampleReducer from "./sampleSlice";
import counterReducer from "./counterSlice";
import shopReducer from './shopSlice';

// ストア　全ての状態が格納されている
export const store = configureStore({
    reducer: {
        sample: sampleReducer,
        counter: counterReducer,
        shop: shopReducer,
    },
})