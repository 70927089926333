import React, { useState } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import Header from '../components/Header';
import { collection, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';


const CouponEdit = ({user}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { data: selectedRecord } = location.state || {}; // stateからデータを取得
  
    if (!selectedRecord) {
      navigate('/couponList');
      
    }

    function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear(); // 年を取得
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月を取得し、1桁なら先頭に0を追加
        const day = date.getDate().toString().padStart(2, '0'); // 日を取得し、1桁なら先頭に0を追加  
        return `${year}-${month}-${day}`; // yyyy-MM-dd形式で返す
    }

    const timestampToDateStr = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000); // TimestampからDateオブジェクトを作成
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月は0始まりのため+1する。2桁になるように処理
        const day = ('0' + date.getDate()).slice(-2); // 日も2桁になるように処理
        return `${year}-${month}-${day}`; // 'YYYY-MM-DD'形式の文字列を返す
    };
    // const formatExpiryStart = formatDate(selectedRecord.expiry_start);
    console.log('!!!!');
    console.log(selectedRecord.expiry_start);
    console.log(selectedRecord.expiry_start.toLocaleDateString());
    console.log(selectedRecord.expiry_start.toISOString());
    console.log(formatDateToYYYYMMDD(selectedRecord.expiry_start));
    // console.log(timestampToDateStr(selectedRecord.expiry_start));


    /////////////////////////
    // クーポン登録時に使用する値
    /////////////////////////
    const userId = user.uid;
    const couponId = location.state.data.id;
    
    const [couponData, setCouponData] = useState({
                                          coupon_name: selectedRecord.coupon_name,
                                          required_point: selectedRecord.required_point,
                                          explanation: selectedRecord.explanation,
                                          expiry_start: formatDateToYYYYMMDD(selectedRecord.expiry_start),
                                          expiry_end: formatDateToYYYYMMDD(selectedRecord.expiry_end),
                                        });

    console.log('????');
    console.log(couponData);

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openFailureDialog, setOpenFailureDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    

    /////////////////////////
    // 各stateの更新処理
    /////////////////////////

    // クーポン情報の設定
    const handleCouponNameChange = (event) => {
        setCouponData({
            ...couponData,
            coupon_name: event.target.value,
        });
    };

    const handleRequiredPointChange = (event) => {
        const numericValue = event.target.value.replace(/[^0-9]/g, '');
        const value = numericValue === '' ? 0 : parseInt(numericValue, 10);
        setCouponData((prevData) => ({
          ...prevData,
          required_point: parseInt(value, 10),
        }));
    };

    const handleExplanationChange = (event) => {
        setCouponData({
            ...couponData,
            explanation: event.target.value,
        });
    };
    
    const handleExpiryStartChange = (event) => {
        console.log('event.target.value????');
        console.log(event.target.value);
        setCouponData({
            ...couponData,
            expiry_start: event.target.value,
        });
    };
    
    const handleExpiryEndChange = (event) => {
        setCouponData({
            ...couponData,
            expiry_end: event.target.value,
        });
    };

    // 確認ダイアログの表示非表示
    const handleOpenConfirmationDialog = () => {
        setOpenConfirmationDialog(true);
    };
    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    // 成功ダイアログの表示非表示
    const handleOpenSuccessDialog = () => {
        setOpenSuccessDialog(true);
    };
    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
    };

    // 失敗ダイアログの表示非表示
    const handleOpenFailureDialog = () => {
        setOpenFailureDialog(true);
    };
    const handleCloseFailureDialog = () => {
        setOpenFailureDialog(false);
    };


    const handleBackToList = () => {
        navigate('/couponList');
        handleCloseSuccessDialog();
        handleCloseFailureDialog();
    };


    /////////////////////////
    // 変更処理
    /////////////////////////
    const updateCouponToCustomer = async (userId, couponId) => {

        // 入力チェック
        if (!couponData.coupon_name) {
            handleCloseConfirmationDialog();
            alert('クーポン名を入力してください。');
            return;
        }

        if (couponData.expiry_start > couponData.expiry_end) {
            handleCloseConfirmationDialog();
            alert('有効期限の終了日は、開始日より後の日付にしてください。');
            return;
        }

        const updatedCouponData = {
            ...couponData,
            // expiry_startとexpiry_endをTimestampに変換
            expiry_start: Timestamp.fromDate(new Date(couponData.expiry_start)),
            expiry_end: Timestamp.fromDate(new Date(couponData.expiry_end)),
        };

        try {
            setLoading(true);
            // カスタマーコレクション内のクーポンコレクションの参照を取得
            const couponsCollectionRef = collection(db, 'customers', userId, 'coupons');
            
            // 対象のドキュメントを参照
            const couponDocRef = doc(couponsCollectionRef, couponId);
            
            // ドキュメントを更新
            // await updateDoc(couponDocRef, couponData);
            await updateDoc(couponDocRef, updatedCouponData);
            
            setLoading(false);
            handleCloseConfirmationDialog();
            handleOpenSuccessDialog();
        } catch (error) {
            console.error('エラー', error);
            setLoading(false);
            handleCloseConfirmationDialog();
            handleOpenFailureDialog();
        }

    };

  return (
    <div>

        {/* スピナー */}
        {loading && (
            <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bgcolor="rgba(0, 0, 0, 0.3)"
                zIndex={9999}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {/* <CircularProgress color="inherit" /> */}
                <CircularProgress />
            </Box>
        )}

        <Header text={'クーポン編集'} return_path={'/couponList'} />
        <Box mt={2}>
            <TextField
            label="クーポン名"
            fullWidth
            variant="outlined"
            margin="normal"
            InputProps={{
                placeholder: '新規入会クーポン！', // プレースホルダーの設定
            }}
            value={couponData.coupon_name}
            onChange={handleCouponNameChange}
            />
        </Box>
        <Box>
            <TextField
            label="説明"
            fullWidth
            variant="outlined"
            margin="normal"
            InputProps={{
                placeholder: 'お会計から500円割引', // プレースホルダーの設定
            }}
            value={couponData.explanation}
            onChange={handleExplanationChange}
            />
        </Box>
        <Box>
            <TextField
            label="必要ポイント数"
            fullWidth
            variant="outlined"
            margin="normal"
            inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
            }}
            value={couponData.required_point}
            onChange={handleRequiredPointChange}
            />
        </Box>
        <Box>
            <TextField
            label="有効期限(開始)"
            type="date"
            fullWidth
            variant="outlined"
            margin="normal"
            // value={timestampToDateStr(couponData.expiry_start)}
            // value={couponData.expiry_start.toLocaleDateString()}
            value={couponData.expiry_start}
            onChange={handleExpiryStartChange}
            />
        </Box>
        <Box>
            <TextField
            label="有効期限(終了)"
            type="date"
            fullWidth
            variant="outlined"
            margin="normal"
            // value={timestampToDateStr(couponData.expiry_end)}
            value={couponData.expiry_end}
            onChange={handleExpiryEndChange}
            />
        </Box>
        <Box mt={2} mb={2}>
            <Button variant="contained" color="primary" onClick={handleOpenConfirmationDialog}>
                変更
            </Button>
        </Box>

        {/* 確認のダイアログ */}
        <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
            <DialogContent>
                <p>変更してよろしいですか？</p>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center'}}>
                <Button onClick={handleCloseConfirmationDialog} disabled={loading}>キャンセル</Button>
                <Button onClick={() => updateCouponToCustomer(userId, couponId)} disabled={loading}>OK</Button>
            </DialogActions>
        </Dialog>

        {/* 成功ダイアログ */}
        <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
            <DialogContent>
                <p>変更が完了しました！</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBackToList}>戻る</Button>
            </DialogActions>
        </Dialog>

        {/* 失敗ダイアログ */}
        <Dialog open={openFailureDialog} onClose={handleCloseFailureDialog}>
            <DialogContent>
                <p>変更できませんでした。</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBackToList}>戻る</Button>
            </DialogActions>
        </Dialog>
    </div>
  );
};

export default CouponEdit;
