import { createSlice } from "@reduxjs/toolkit";

// スライス　→ action,state,reducer
export const sampleSlice = createSlice({
    name: 'sample',
    initialState: { value: 0 },
    reducers: {
      increment: (state) => {
        state.value += 1;
      },
      plus: (state, action) => {
        state.value += action.payload;
      },
    },
})

console.log('9999999');
console.log(sampleSlice.reducer);

// アクションクリエーター　Viewで使用する
export const { increment, plus } = sampleSlice.actions;

// リデューサー　Storeで使用する
export default sampleSlice.reducer;
