import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import jsQR from 'jsqr';
import Header from '../components/Header';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


function PointReading({user}) {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const animationFrameId = useRef(null);
  const [qrcode, setQrcode] = useState(null);
  const [stream, setStream] = useState(null);


  const stopCamera = useCallback(() => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
  }, [stream]); // ここで `stopCamera` が依存する値を指定します。


  useEffect(() => {
    return () => stopCamera();
  }, [stopCamera]);

  

  const handleQRCode = async (videoRef, canvasElement, canvasContext) => {
    if (!videoRef.current) return;
    if (qrcode) return;

    canvasContext.drawImage(videoRef.current, 0, 0, canvasElement.width, canvasElement.height);
    const imageData = canvasContext.getImageData(0, 0, canvasElement.width, canvasElement.height);
    const code = jsQR(imageData.data, canvasElement.width, canvasElement.height);

    if (code) {
      console.log('QR Code Value:', code.data);
      setQrcode(code.data);
      stopCamera();

      // Firestoreからデータを取得
      const userId = code.data;
      const shopId = user.uid;

      try {
        if (userId && shopId) {
          // Firestoreからデータを取得
          const docRef = doc(db, `users/${userId}/cards/${shopId}`);
          const docSnap = await getDoc(docRef);
          const shopDocRef = doc(db, `customers/${shopId}`);
          const shopDocSnap = await getDoc(shopDocRef);
          
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            // ここでポイントの確認画面を表示するロジックを追加
            navigate('/pointUsing', { state: { cardDocData: docSnap.data(), shopDocData: shopDocSnap.data(),　userId: userId, shopId: shopId } });
            // navigate('/pointUsing', { state: { cardDocData: docSnap.data(), userId: userId, shopId: shopId } });
          } else {
            console.log("No such document!");
          }

        } else {
          alert('正しいQRコードを読み取ってください???');
        }
      } catch (e) {
        alert('正しいQRコードを読み取ってください!!!');
      }
    }
  };

  const animate = (videoRef, canvasElement, canvasContext) => {
    handleQRCode(videoRef, canvasElement, canvasContext);
    animationFrameId.current = requestAnimationFrame(() => animate(videoRef, canvasElement, canvasContext));
  };

  const videoContainerStyle = {
    width: '90%', // コンテナの幅を設定
    height: '300px', // コンテナの高さを設定
    position: 'relative', // 子要素の配置を制御するために relative を指定
    overflow: 'hidden', // コンテナ外のビデオ部分を非表示にする
    border: 'solid 1px', // 枠線を設定
    margin: '0 auto', // 中央揃え
  };
  
  const videoStyle = {
    position: 'absolute', // コンテナ内での絶対位置指定
    top: '50%', // 上辺をコンテナの中央に
    left: '50%', // 左辺をコンテナの中央に
    transform: 'translate(-50%, -50%)', // 中央に配置
    minHeight: '100%', // 最小限コンテナの高さに合わせる
    minWidth: '100%', // 最小限コンテナの幅に合わせる
  };

  const startCamera = async () => {
    try {
      // スマホ用の設定　ーーーーーーーー
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: {
            exact: 'environment',
          },
        },
      });
      // ーーーーーーーーーーーーーーー


      // PC用の設定　ーーーーーーーーーーーーーーー
      // const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
      // ーーーーーーーーーーーーーーーーーーーーー
      
      
      // 以下、共通
      videoRef.current.srcObject = newStream;
      setStream(newStream);

      videoRef.current.play().then(() => {
        const canvasElement = document.createElement('canvas');
        const canvasContext = canvasElement.getContext('2d');

        // ここでビデオの寸法を使用してキャンバスのサイズを設定
        canvasElement.width = videoRef.current.videoWidth;
        canvasElement.height = videoRef.current.videoHeight;

        animate(videoRef, canvasElement, canvasContext);
      });
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  return (
    <div>
      <Header text={'ポイントカード読取'} />
      <div>
        <p>お客様のQRコードを写してください</p>
        <div style={videoContainerStyle}>
          <video ref={videoRef} style={videoStyle} playsInline />
        </div>
        {/* <video ref={videoRef} style={{ width: '90%', height: '300px', border: 'solid 1px' }} playsInline /> */}
        <Button variant="contained" color="primary" onClick={startCamera} style={{ marginTop: '25px' }}>
          読取開始
        </Button>
      </div>
    </div>
  );
}

export default PointReading;
