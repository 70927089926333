import React, { useState } from 'react';
import { Button, CircularProgress, Box, TextField } from '@mui/material';
import Header from '../components/Header';

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const ShopImageUpload = ({user}) => {
  const userId = user.uid;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!image) {
      alert('画像を選択してください');
      return;
    }

    try {
      setLoading(true);

      // 画像をFirebase Storageにアップロード
      const storage = getStorage();
      const storageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(storageRef, image);

      // アップロード後、Firebase Storageから画像のダウンロードURLを取得
      const img_url = await getDownloadURL(storageRef);

      // 取得したURLをFirestoreのcustomersコレクションの特定のドキュメントに保存
      const customerDocRef = doc(db, 'customers', userId);
      await updateDoc(customerDocRef, { img_url });


      alert('画像のアップロードが成功しました');

      // アップロードが成功したら画像をリセット
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('画像のアップロードに失敗しました');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header text={'画像アップロード'} />
      <Box mt={2}>
        <TextField
          type="file"
          onChange={handleFileChange}
          fullWidth
          inputProps={{ accept: 'image/*' }}
        />
      </Box>
      <Box mt={2} mb={2}>
        <Button variant="contained" color="primary" onClick={handleUpload} disabled={loading}>
          {loading ? <CircularProgress size={20} /> : 'アップロード'}
        </Button>
      </Box>
    </div>
  );
};

export default ShopImageUpload;
