import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // React.StrictMode　→　レンダリングなどのエラーを検知するための開発モード。そのために、レンダリングが2回実行される。本番では無視される。 
  // <React.StrictMode>
  //   <BrowserRouter>
  //     <App />
  //   </BrowserRouter>
  // </React.StrictMode>

    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
);

// アプリでパフォーマンスの計測をしたい場合は、結果をログに記録する
// 　→ 例: reportWebVitals(console.log)
// またはアナリティクスのエンドポイントに送信する
// 　→ 詳細: https://bit.ly/CRA-vitals
reportWebVitals();
