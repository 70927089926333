import * as React from 'react';
import { Link } from 'react-router-dom';

// ヘッダー用
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Drawer用
import Drawer from '@mui/material/Drawer';
import NotificationsIcon from '@mui/icons-material/Notifications';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { auth, app } from '../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

export default function Header({ text, return_path }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);


  const drawerHandling = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleBillingPortalClick = async () => {
    try {
      const functions = getFunctions(app, 'asia-northeast2');
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      const { data } = await createPortalLink({ returnUrl: window.location.origin });
      // const { data } = await createPortalLink({ customer: 'cus_PaEO2CdPyhM4gy', returnUrl: window.location.origin });
      window.location.assign(data.url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    // ログアウトの確認ダイアログを開く
    setLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    // ログアウトの確認ダイアログを閉じ、ログアウト処理を実行
    setLogoutDialogOpen(false);
    auth.signOut();
  };

  const cancelLogout = () => {
    // ログアウトの確認ダイアログを閉じる
    setLogoutDialogOpen(false);
  };

  let jsxHeader;

  let drawer = (
    <Drawer anchor="left" open={drawerOpen} onClose={drawerHandling}>
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/faq">
              <ListItemIcon>
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="よくある質問" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={handleBillingPortalClick}>
              <ListItemIcon>
                <CurrencyYenIcon />
              </ListItemIcon>
              <ListItemText primary="決済情報" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/termsOfService">
              <ListItemIcon>
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText primary="利用規約" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/privacyPolicy">
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText primary="プライバシーポリシー" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/noticeList">
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="お知らせ" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  // メニュー画面のヘッダー
  if (text === 'メニュー') {
    jsxHeader = (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="notice"
              component={Link}
              to="/noticeList"
            >
              <NotificationsIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {text}
            </Typography>

            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="hamburger-menu"
              onClick={drawerHandling}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {drawer}
      </Box>
    );
  } else if (text === '店舗情報入力') {
    jsxHeader = (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: 'gray', // 背景色をグレーに
          }}
        >
          <Toolbar>
            <IconButton
            sx={{ visibility: 'hidden' }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {text}
            </Typography>

            <IconButton
              sx={{ visibility: 'hidden' }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="hamburger-menu"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    );
  } else if (text === 'プラン選択') {
    jsxHeader = (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: 'gray',
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              component={Link}
              to={return_path ? return_path : "/"}
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {text}
            </Typography>

            <IconButton
              sx={{ visibility: 'hidden' }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="hamburger-menu"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    );
  } else {
    jsxHeader = (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              component={Link}
              to={return_path ? return_path : "/"}
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {text}
            </Typography>

            <IconButton
              sx={{ visibility: 'hidden' }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="hamburger-menu"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }

  return (
    <>
      {jsxHeader}
      {/* ログアウト確認ダイアログ */}
      <Dialog open={logoutDialogOpen} onClose={cancelLogout}>
        {/* <DialogTitle>ログアウト</DialogTitle> */}
        <DialogContent>
          <Typography>本当にログアウトしますか？</Typography>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button onClick={cancelLogout}>キャンセル</Button>
          <Button onClick={confirmLogout}>ログアウト</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
