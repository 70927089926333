import React, { useState } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import Header from '../components/Header';
import { collection, setDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

const CouponNew = ({user}) => {

    const navigate = useNavigate();


    /////////////////////////
    // クーポン登録時に使用する値
    /////////////////////////
    const userId = user.uid;

    const initialCouponData = {
        coupon_name: '',
        required_point: 0,
        explanation: '',
        expiry_start: new Date().toISOString().split('T')[0],
        expiry_end: new Date().toISOString().split('T')[0],
    };

    const [couponData, setCouponData] = useState(initialCouponData);


    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openFailureDialog, setOpenFailureDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    

    /////////////////////////
    // 各stateの更新処理
    /////////////////////////

    // クーポン情報の設定
    const handleCouponNameChange = (event) => {
        setCouponData({
            ...couponData,
            coupon_name: event.target.value,
        });
    };

    const handleRequiredPointChange = (event) => {
        const numericValue = event.target.value.replace(/[^0-9]/g, '');
        const value = numericValue === '' ? 0 : parseInt(numericValue, 10);
        setCouponData((prevData) => ({
          ...prevData,
          required_point: parseInt(value, 10),
        }));
    };

    const handleExplanationChange = (event) => {
        setCouponData({
            ...couponData,
            explanation: event.target.value,
        });
    };
    
    const handleExpiryStartChange = (event) => {
        setCouponData({
            ...couponData,
            expiry_start: event.target.value,
        });
    };

    const handleExpiryEndChange = (event) => {
        setCouponData({
            ...couponData,
            expiry_end: event.target.value,
        });
    };

    // 確認ダイアログの表示非表示
    const handleOpenConfirmationDialog = () => {
        setOpenConfirmationDialog(true);
    };
    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    // 成功ダイアログの表示非表示
    const handleOpenSuccessDialog = () => {
        setOpenSuccessDialog(true);
    };
    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
    };

    // 失敗ダイアログの表示非表示
    const handleOpenFailureDialog = () => {
        setOpenFailureDialog(true);
    };
    const handleCloseFailureDialog = () => {
        setOpenFailureDialog(false);
    };


    const handleBackToList = () => {
        navigate('/couponList');
        handleCloseSuccessDialog();
    };

    const handleContinueCreating = () => {
        setCouponData(initialCouponData);
        handleCloseSuccessDialog();
    };

    

    /////////////////////////
    // 保存時の処理
    /////////////////////////
    const addCouponToCustomer = async (userId) => {

        // 入力チェック
        if (!couponData.coupon_name) {
            handleCloseConfirmationDialog();
            alert('クーポン名を入力してください。');
            return;
        }

        if (couponData.expiry_start > couponData.expiry_end) {
            handleCloseConfirmationDialog();
            alert('有効期限の終了日は、開始日より後の日付にしてください。');
            return;
        }

        const setCouponData = {
            ...couponData,
            // expiry_startとexpiry_endをTimestampに変換
            expiry_start: Timestamp.fromDate(new Date(couponData.expiry_start)),
            expiry_end: Timestamp.fromDate(new Date(couponData.expiry_end)),
        };

        try {
            // throw new Error('例外が発生しました');
            setLoading(true);
            // await new Promise(resolve => setTimeout(resolve, 3000));
            // カスタマーコレクション内のクーポンコレクションの参照を取得
            const couponsCollectionRef = collection(db, 'customers', userId, 'coupons');
          
            // TODO:コレクションがない場合にエラーが出る場合は、以下の処理を追加
            // クーポンコレクションが存在するかどうか確認
            // const couponsQuery = query(couponsCollectionRef);
            // const snapshot = await getDocs(couponsQuery);
            
            // if (snapshot.empty) {
                //   // クーポンコレクションが存在しない場合は新規作成
            //   await setDoc(doc(db, 'customers', userId), { coupons: {} }, { merge: true });
            // }
            
            // 新しいドキュメントをクーポンコレクションに追加
            const couponRef = doc(couponsCollectionRef);
            // await setDoc(couponRef, couponData);
            await setDoc(couponRef, setCouponData);
            
            setLoading(false);
            handleCloseConfirmationDialog();
            handleOpenSuccessDialog();
        } catch (error) {
            console.error('エラー', error);
            setLoading(false);
            handleCloseConfirmationDialog();
            handleOpenFailureDialog();
        }

    };

  return (
    <div>
        {/* スピナー */}
        {loading && (
            <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bgcolor="rgba(0, 0, 0, 0.3)"
                zIndex={9999}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {/* <CircularProgress color="inherit" /> */}
                <CircularProgress />
            </Box>
        )}

        <Header text={'クーポン追加'} return_path={'/couponList'} />
        <Box mt={2}>
            <TextField
            label="クーポン名"
            fullWidth
            variant="outlined"
            margin="normal"
            InputProps={{
                placeholder: '新規入会クーポン！', // プレースホルダーの設定
            }}
            value={couponData.coupon_name}
            onChange={handleCouponNameChange}
            />
        </Box>
        <Box>
            <TextField
            label="説明"
            fullWidth
            variant="outlined"
            margin="normal"
            InputProps={{
                placeholder: 'お会計から500円割引', // プレースホルダーの設定
            }}
            value={couponData.explanation}
            onChange={handleExplanationChange}
            />
        </Box>
        <Box>
            <TextField
            label="必要ポイント数"
            fullWidth
            variant="outlined"
            margin="normal"
            inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
            }}
            value={couponData.required_point}
            onChange={handleRequiredPointChange}
            />
        </Box>
        <Box>
            <TextField
            label="有効期限(開始)"
            type="date"
            fullWidth
            variant="outlined"
            margin="normal"
            value={couponData.expiry_start}
            onChange={handleExpiryStartChange}
            />
        </Box>
        <Box>
            <TextField
            label="有効期限(終了)"
            type="date"
            fullWidth
            variant="outlined"
            margin="normal"
            value={couponData.expiry_end}
            onChange={handleExpiryEndChange}
            />
        </Box>
        <Box mt={2} mb={2}>
            <Button variant="contained" color="primary" onClick={handleOpenConfirmationDialog}>
                保存
            </Button>
        </Box>


        {/* 確認のダイアログ */}
        <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
            <DialogContent>
                <p>保存してよろしいですか？</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmationDialog} disabled={loading}>キャンセル</Button>
                <Button onClick={() => addCouponToCustomer(userId)} disabled={loading}>OK</Button>
            </DialogActions>
        </Dialog>

        {/* 成功ダイアログ */}
        <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
            <DialogContent>
                <p>保存が完了しました！</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBackToList}>一覧に戻る</Button>
                <Button onClick={handleContinueCreating}>続けてクーポンを作成する</Button>
            </DialogActions>
        </Dialog>

        {/* 失敗ダイアログ */}
        <Dialog open={openFailureDialog} onClose={handleCloseFailureDialog}>
            <DialogContent>
                <p>保存できませんでした。</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBackToList}>戻る</Button>
            </DialogActions>
        </Dialog>
    </div>
  );
};

export default CouponNew;
