import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';

const NoticeDetail = () => {
  const location = useLocation();
  const { notice, formattedDate } = location.state || {}; // stateがundefinedの場合は空のオブジェクトを使用

  // noticeがundefinedの場合のフォールバック値や条件付きレンダリングを追加
  if (!notice) {
    return <div>お知らせを読み込めませんでした。</div>;
  }

  return (
    <div>
      <Header text={'お知らせ'} return_path={'/noticeList'} />
      <Box sx={{ textAlign: 'left', mx: 1 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, fontSize: '0.875rem', color: 'grey.500' }}>{formattedDate}</Typography>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>{notice.title}</Typography>
        <Divider sx={{ mb: 1 }}/>
        <Typography variant="body1">{notice.content}</Typography>
      </Box>
    </div>
  );
};

export default NoticeDetail;
