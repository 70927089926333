import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCKV1P0uKiRllGLRUXuBVYbj5LfuRMmaO8",
    // authDomain: "conekuta-app-test01.firebaseapp.com",
    authDomain: "shop.conekuta.com",
    projectId: "conekuta-app-test01",
    storageBucket: "conekuta-app-test01.appspot.com",
    messagingSenderId: "294541297853",
    appId: "1:294541297853:web:d23de6888a5f54628e0fd4",
    measurementId: "G-B7HNPMFBBG"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();

const functions = getFunctions(app, 'asia-northeast2');
const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

export {
    app
    , auth
    , db
    , provider
    , functions
    , createPortalLink
};