import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import Header from '../components/Header';
import { collection, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

const ShopInfo = ({user}) => {
  const userId = user.uid;

  const [shopData, setShopData] = useState({
    shop_name: '',
    point_rate: '',
  }); 

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFailureDialog, setOpenFailureDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  // データ取得
  const fetchData = useCallback(async () => {
    try {
      // 対象のドキュメントを参照
      const shopDocRef = doc(db, 'customers', userId);
      
      // ドキュメントを取得
      const docSnap = await getDoc(shopDocRef);

      if (docSnap.exists()) {
        setShopData(docSnap.data());
      } else {
        // ドキュメントが存在しない場合
        console.log("No such document!");
      }

    } catch (error) {
      console.error('Firestoreデータ取得エラー', error);
      // エラーハンドリングのロジックを追加
    }
  },[userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleShopNameChange = (event) => {
    setShopData({
        ...shopData,
        shop_name: event.target.value,
    });
  };
  
  const handlePointRateChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    const value = numericValue === '' ? 0 : parseInt(numericValue, 10);
    setShopData({
        ...shopData,
        point_rate: parseInt(value, 10),
    });
};

  const handleBack = () => {
    handleCloseSuccessDialog();
    handleCloseFailureDialog();
  };


  // 確認ダイアログの表示非表示
  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  // 成功ダイアログの表示非表示
  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };
  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  // 失敗ダイアログの表示非表示
  const handleOpenFailureDialog = () => {
    setOpenFailureDialog(true);
  };
  const handleCloseFailureDialog = () => {
    setOpenFailureDialog(false);
  };

  /////////////////////////
  // 変更処理
  /////////////////////////
  const updateCustomer = async () => {

    // 入力チェック
    if (!shopData.shop_name) {
      handleCloseConfirmationDialog();
      alert('店舗名を入力してください。');
      return;
    }

    try {
      setLoading(true);
      // カスタマーコレクション内のクーポンコレクションの参照を取得
      const shopsCollectionRef = collection(db, 'customers');
      
      // 対象のドキュメントを参照
      const shopDocRef = doc(shopsCollectionRef, userId);
      
      // ドキュメントを更新
      await updateDoc(shopDocRef, shopData);
      
      setLoading(false);
      handleCloseConfirmationDialog();
      handleOpenSuccessDialog();
    } catch (error) {
      console.error('エラー', error);
      setLoading(false);
      handleCloseConfirmationDialog();
      handleOpenFailureDialog();
    }

  };

  return (
    <div>

      {/* スピナー */}
      {loading && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bgcolor="rgba(0, 0, 0, 0.3)"
            zIndex={9999}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* <CircularProgress color="inherit" /> */}
            <CircularProgress />
          </Box>
      )}

      <Header text={'店舗情報管理'} />
      <Box>
        <TextField
          label="店舗名"
          fullWidth
          variant="outlined"
          margin="normal"
          value={shopData.shop_name}
          onChange={handleShopNameChange}
        />
      </Box>
      <Box>
          <TextField
          label="ポイント付与率（〇〇円で1ポイント）"
          fullWidth
          variant="outlined"
          margin="normal"
          inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
          }}
          value={shopData.point_rate}
          onChange={handlePointRateChange}
          />
      </Box>
      <Link to="/shopImageUpload">
          ポイントカード用の画像をアップロードする
      </Link>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleOpenConfirmationDialog}>
          変更
        </Button>
      </Box>

      {/* 確認のダイアログ */}
      <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
        <DialogContent>
          <p>変更してよろしいですか？</p>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button onClick={handleCloseConfirmationDialog} disabled={loading}>キャンセル</Button>
          <Button onClick={() => updateCustomer()} disabled={loading}>OK</Button>
        </DialogActions>
      </Dialog>

      {/* 成功ダイアログ */}
      <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
        <DialogContent>
          <p>変更が完了しました！</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBack}>戻る</Button>
        </DialogActions>
      </Dialog>

      {/* 失敗ダイアログ */}
      <Dialog open={openFailureDialog} onClose={handleCloseFailureDialog}>
        <DialogContent>
          <p>変更できませんでした。</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBack}>戻る</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShopInfo;
