import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Pagination,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Typography,
  CardActions,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'; // レコード編集用のLinkを追加
import Header from '../components/Header';
import { collection, getDocs, query, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';


const PAGE_SIZE = 4; // 1ページに表示するレコード数

const CouponList = ({user}) => {
  const navigate = useNavigate();
  const userId = user.uid;
  
  const [loading, setLoading] = useState(false);
  
  const [data, setData] = useState([]); // 表示データ
  const [recordId, setRecordId] = useState(''); // クーポンのドキュメントID
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  const [openSingleDeleteDialog, setOpenSingleDeleteDialog] = useState(false);
  const [openMultipleDeleteDialog, setOpenMultipleDeleteDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFailureDialog, setOpenFailureDialog] = useState(false);


  const formatDate = (timestamp) => {
    const date = timestamp.toDate(); // Firestore TimestampをDateに変換
    // return date.toLocaleDateString(); // 'yyyy/MM/dd'の形式で表示
    return date;
  };
  
  // データ取得
  const fetchData = useCallback(async () => {
    try {
      const couponsCollectionRef = collection(db, 'customers', userId, 'coupons');
      const q = query(
        couponsCollectionRef,
        // orderBy('name'), // ここでデータの並び替えを指定することも可能
      );
      const querySnapshot = await getDocs(q);

      
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        // Timestamp型のフィールドをDate型に変換
        const data = doc.data();
        const expiry_start = formatDate(data.expiry_start);
        // const expiry_end = data.expiry_end.toDate();
        const expiry_end = formatDate(data.expiry_end);
        fetchedData.push({
          id: doc.id,
          // expiry_start: expiry_start,
          // expiry_end: expiry_end,
          ...data,
          expiry_start,
          expiry_end,
        });
      });
      setData(fetchedData);
    } catch (error) {
      console.error('Firestoreデータ取得エラー', error);
      // エラーハンドリングのロジックを追加
    }
  },[userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // 削除ボタンクリック時の処理
  const handleDeleteButtonClick = (recode_id) => {
    setRecordId(recode_id);
    handleOpenSingleDeleteDialog();
  };

  // 確認ダイアログの表示非表示
  const handleOpenSingleDeleteDialog = () => {
    setOpenSingleDeleteDialog(true);
  };
  const handleCloseSingleDeleteDialog = () => {
    setOpenSingleDeleteDialog(false);
  };

  const handleOpenMultipleDeleteleDialog = () => {
    setOpenMultipleDeleteDialog(true);
  };
  const handleCloseMultipleDeleteDialog = () => {
    setOpenMultipleDeleteDialog(false);
  };

  // 成功ダイアログの表示非表示
  const handleOpenDeleteSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };
  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  // 失敗ダイアログの表示非表示
  const handleOpenDeleteFailureDialog = () => {
    setOpenFailureDialog(true);
  };
  const handleCloseFailureDialog = () => {
    setOpenFailureDialog(false);
  };

  // 編集
  const handleEdit = (recordId) => {
    const selectedRecord = data.find((record) => record.id === recordId);
    navigate('/couponEdit', { state: { data: selectedRecord } });
    
  };
  
  // 削除
  const handleSingleDelete = async () => {
    try {
      setLoading(true);
      // カスタマーコレクション内のクーポンコレクションの参照を取得
      const couponsCollectionRef = collection(db, 'customers', userId, 'coupons');
      
      // 対象のドキュメントを参照
      const couponDocRef = doc(couponsCollectionRef, recordId);
      
      // ドキュメントを削除
      await deleteDoc(couponDocRef);
      
      setLoading(false);
      handleCloseSingleDeleteDialog();
      handleOpenDeleteSuccessDialog();
      fetchData();
    } catch (error) {
      console.error('エラー', error);
      setLoading(false);
      handleCloseSingleDeleteDialog();
      handleOpenDeleteFailureDialog();
    }
  };

  // 一括削除
  const handleMultipleDelete = async () => {
    try {
      setLoading(true);
      const couponsCollectionRef = collection(db, 'customers', userId, 'coupons');
      const deletePromises = selectedRecords.map((couponId) => {
        const couponDocRef = doc(couponsCollectionRef, couponId);
        return deleteDoc(couponDocRef);
      });
      await Promise.all(deletePromises);
      setLoading(false);
      setSelectedRecords([]); // 選択されたレコードのリセット
      handleCloseMultipleDeleteDialog();
      handleOpenDeleteSuccessDialog();
      fetchData();
    } catch (error) {
      console.error('エラー', error);
      setLoading(false);
      handleCloseMultipleDeleteDialog();
      handleOpenDeleteFailureDialog();
    }
  };

  const handleCheckboxChange = (recordId) => {
    // 既にsetSelectedRecordsにあれば除外する
    // 無ければ追加する
    if (selectedRecords.includes(recordId)) {
      setSelectedRecords(selectedRecords.filter((id) => id !== recordId));
    } else {
      setSelectedRecords([...selectedRecords, recordId]);
    }
    console.log('selectedRecords');
    console.log(selectedRecords);
  };

  // Reactのコンポーネントが自動でeventを引数で渡しているため、第一引数はeventにしておく必要がある
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data];
  if (sortConfig.key) {
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const displayedData = sortedData.slice(startIndex, endIndex);


  return (
    <div>

      {/* スピナー */}
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.3)"
          zIndex={9999}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* <CircularProgress color="inherit" /> */}
          <CircularProgress />
        </Box>
      )}

      <Header text={'クーポン一覧'} />

      <Box mt={2} mb={2} display="flex" justifyContent="center" gap="16px">
        <Button variant="contained" component={Link} to="/couponNew">
          追加
        </Button>
        <Button variant="contained" onClick={handleOpenMultipleDeleteleDialog}>
          一括削除
        </Button>
      </Box>

     {/* 一覧 */}
     <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        {displayedData.map((record) => (
          <Card key={record.id} style={{width: '90%'}} elevation={3}>
            <CardContent>
              <Typography color="text.secondary">
                {record.expiry_start.toLocaleDateString()}〜{record.expiry_end.toLocaleDateString()}
                {/* {formatDate(record.expiry_start)}〜{formatDate(record.expiry_end)} */}
              </Typography>
              <Typography variant="h5" component="div">
                {record.coupon_name}
              </Typography>
              <Typography component="div">
                {record.explanation}
              </Typography>
            </CardContent>
            <CardActions style={{justifyContent: 'center'}}>
              <Button size="small" onClick={() => navigate('/couponEdit', { state: { data: record } })}>編集</Button>
              <Button size="small" onClick={() => {
                setRecordId(record.id);
                setOpenSingleDeleteDialog(true);
              }}>削除</Button>
              <Checkbox
                checked={selectedRecords.includes(record.id)}
                onChange={() => handleCheckboxChange(record.id)}
              />
            </CardActions>
          </Card>
        ))}
      </Box>

      {/* ページネーション */}
      <Box display="flex" justifyContent="center" mt={1}>
        <Pagination
          count={Math.ceil(sortedData.length / PAGE_SIZE)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>


      {/* 確認のダイアログ */}
      <Dialog open={openSingleDeleteDialog} onClose={handleCloseSingleDeleteDialog}>
          <DialogContent>
              <p>削除してよろしいですか？</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseSingleDeleteDialog} disabled={loading}>キャンセル</Button>
              <Button onClick={() => handleSingleDelete()} disabled={loading}>OK</Button>
          </DialogActions>
      </Dialog>

      <Dialog open={openMultipleDeleteDialog} onClose={handleCloseMultipleDeleteDialog}>
          <DialogContent>
              <p>一括で削除してよろしいですか？</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseMultipleDeleteDialog} disabled={loading}>キャンセル</Button>
              <Button onClick={() => handleMultipleDelete()} disabled={loading}>OK</Button>
          </DialogActions>
      </Dialog>

      {/* 成功ダイアログ */}
      <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
          <DialogContent>
              <p>削除が完了しました！</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseSuccessDialog}>戻る</Button>
          </DialogActions>
      </Dialog>

      {/* 失敗ダイアログ */}
      <Dialog open={openFailureDialog} onClose={handleCloseFailureDialog}>
          <DialogContent>
              <p>削除できませんでした。</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseFailureDialog}>戻る</Button>
          </DialogActions>
      </Dialog>
    </div>
  );
};

export default CouponList;
