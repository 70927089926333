import { createSlice } from '@reduxjs/toolkit';

const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    shopName: '',
    pointRate: 200,
  },
  reducers: {
    setShopName: (state, action) => {
      state.shopName = action.payload;
    },
    clearShopName: (state) => {
      state.shopName = '';
    },
    setPointRate: (state, action) => {
      state.pointRate = action.payload;
    },

  },
});

export const { setShopName, clearShopName, setPointRate } = shopSlice.actions;
export default shopSlice.reducer;
