import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Box, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { collection, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';



function PointUsing() {
    const navigate = useNavigate();
    const location = useLocation();
    const cardDocData = location.state?.cardDocData;
    const shopDocData = location.state?.shopDocData;
    const userId = location.state?.userId;
    const shopId = location.state?.shopId;


    // TODO:Reduxのstoreで管理。値がない場合はデータをfirestoreから取得
    const pointRate = shopDocData.point_rate;
    
    const [formValues, setFormValues] = useState({
        current_point: cardDocData ? cardDocData.point : 0,
        pay_amount: '',
        use_point: '',
        pay_amount_point_applying: 0,
        additional_point: 0,
        new_point: cardDocData ? cardDocData.point : 0,
        new_total_point: cardDocData ? cardDocData.point : 0,
    });

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openFailureDialog, setOpenFailureDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // 支払金額（ポイント適用後）から追加ポイントを計算
        const payAmountPointApplying = parseFloat(formValues.pay_amount_point_applying || 0);
        const additionalPoint = Math.floor(payAmountPointApplying / pointRate); // 小数点以下は切り捨て

        // 新ポイントを計算
        const currentTotalPoint = parseFloat(cardDocData.total_point || 0);
        const currentPoint = parseFloat(formValues.current_point || 0);
        const usePoint = parseFloat(formValues.use_point || 0);
        const newPoint = currentPoint - usePoint + additionalPoint;
        const newTotalPoint = currentTotalPoint + additionalPoint;

 

        // additional_point の値を更新
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            additional_point: additionalPoint,
            new_point: newPoint,
            new_total_point: newTotalPoint,
        }));
    }, [formValues.pay_amount_point_applying, pointRate, formValues.use_point, formValues.current_point]);

    // userId もしくは shopId が無効な場合、一つ前の画面に戻る
    useEffect(() => {
        if (!userId || !shopId) {
            navigate(-1); // 一つ前の画面に戻る
        }
    }, [userId, shopId, navigate]);
    
    // 一つの汎用的なhandleChange関数で、
    // 各入力フィールドの値を適切な状態にマッピングします。
    const handleChange = (event) => {
        // inputのnameとvalueを取得
        const { name, value } = event.target;
        let numericValue = parseFloat(value);

        // 支払金額の場合
        if (name === 'pay_amount') {
            // 入力値が数値かつ、0以上ならば状態を更新
            if (!isNaN(numericValue) && numericValue >= 0) {       
                numericValue = Math.floor(numericValue);        
                const updatedFormValues = {
                    ...formValues,
                    [name]: numericValue.toString(),
                };
            
                // 支払金額または使用するポイントが変更された場合、支払金額（ポイント適用後）を更新
                const payAmount = parseFloat(updatedFormValues.pay_amount || 0); // 空の場合は0として扱う
                const usePoint = parseFloat(updatedFormValues.use_point || 0); // 空の場合は0として扱う
                const payAmountPointApplying = Math.max(0, payAmount - usePoint); // 負の値を防ぐ
        
                // 更新された値をセット
                setFormValues({
                ...updatedFormValues,
                pay_amount_point_applying: payAmountPointApplying.toString(),
                });
            } else {
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
            }
        } else if (name === 'use_point') {
            // 使用するポイントの入力値が現在のポイントを超えないことを確認
            if (!isNaN(numericValue) && numericValue >= 0) {
                if (numericValue <= formValues.current_point) { 
                    numericValue = Math.floor(numericValue);                      
                    const updatedFormValues = {
                        ...formValues,
                        [name]: numericValue.toString(),
                    };
                
                    // 支払金額または使用するポイントが変更された場合、支払金額（ポイント適用後）を更新
                    const payAmount = parseFloat(updatedFormValues.pay_amount || 0); // 空の場合は0として扱う
                    const usePoint = parseFloat(updatedFormValues.use_point || 0); // 空の場合は0として扱う
                    const payAmountPointApplying = Math.max(0, payAmount - usePoint); // 負の値を防ぐ

                    if (usePoint <= payAmount) {
                        // 更新された値をセット
                        setFormValues({
                            ...updatedFormValues,
                            pay_amount_point_applying: payAmountPointApplying.toString(),
                        });
                    } else {
                        alert('支払金額を超えるポイントは使用できません。');
                    }
                } else {
                    alert('保有ポイントを超えるポイントは使用できません。');
                }
            } else {
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
            }
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    /////////////////////////
    // 変更処理
    /////////////////////////
    const updatePointToCard = async (userId, shopId, newPoint, newTotalPoint) => {

        // 入力チェック
        if (!formValues.pay_amount) {
          handleCloseConfirmationDialog();
          alert('お支払金額を入力してください。');
          return;
      }

        try {
            setLoading(true);
            const cardsCollectionRef = collection(db, 'users', userId, 'cards');
            const cardDocRef = doc(cardsCollectionRef, shopId);
            
            // ドキュメントを更新
            await updateDoc(cardDocRef, { point: newPoint, total_point: newTotalPoint});
            
            setLoading(false);
            handleCloseConfirmationDialog();
            handleOpenSuccessDialog();
            // navigate('/pointReading');
            // alert('成功しました');
            // return;
        } catch (error) {
            console.error('エラー', error);
            setLoading(false);
            handleCloseConfirmationDialog();
            handleOpenFailureDialog();
            // navigate('/pointReading');
            // alert('失敗しました');
            // return;
        }

    };


    // 確認ダイアログの表示非表示
    const handleOpenConfirmationDialog = () => {
        setOpenConfirmationDialog(true);
    };
    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    // 成功ダイアログの表示非表示
    const handleOpenSuccessDialog = () => {
        setOpenSuccessDialog(true);
    };
    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
        navigate('/pointReading'); 
    };

    // 失敗ダイアログの表示非表示
    const handleOpenFailureDialog = () => {
        setOpenFailureDialog(true);
    };
    const handleCloseFailureDialog = () => {
        setOpenFailureDialog(false);
        navigate('/pointReading'); 
    };


    return (
        <div>
            {/* スピナー */}
            {loading && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    bgcolor="rgba(0, 0, 0, 0.3)"
                    zIndex={9999}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {/* <CircularProgress color="inherit" /> */}
                    <CircularProgress />
                </Box>
            )}

            <Header text={'ポイントを使う/付ける'} />
            <div>
                <p>お支払金額と使用するポイントを入力してください</p>
                <Box mt={2}>
                    <TextField
                    label="保有ポイント"
                    name='current_point'
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                        type: 'number',
                        readOnly: true,
                        style: { backgroundColor: '#f0f0f0' },
                    }}
                    value={formValues.current_point}
                    />
                </Box>
                <Box>
                    <TextField
                    label="お支払金額"
                    name='pay_amount'
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                        type: 'number',
                        min: 0,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    value={formValues.pay_amount}
                    onChange={handleChange}
                    />
                </Box>
                <Box>
                    <TextField
                    label="使用するポイント"
                    name='use_point'
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                        type: 'number',
                        min: 0,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    value={formValues.use_point}
                    onChange={handleChange}
                    />
                </Box>
                <Box >
                    <TextField
                    label="お支払金額（ポイント適用後）"
                    name='pay_amount_point_applying'
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                        type: 'number',
                        readOnly: true,
                        style: { backgroundColor: '#f0f0f0' },
                    }}
                    value={formValues.pay_amount_point_applying}
                    />
                </Box>
                <Box >
                    <TextField
                    label={`加算ポイント　※${pointRate}円ごとに1ポイント`}
                    name='additional_point'
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                        type: 'number',
                        readOnly: true,
                        style: { backgroundColor: '#f0f0f0' },
                    }}
                    value={formValues.additional_point}
                    />
                </Box>

                <Box mt={2} mb={2} display="flex" justifyContent="center" gap="16px">
                    <Button variant="text" color="primary" onClick={handleOpenConfirmationDialog}>
                        キャンセル
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleOpenConfirmationDialog}>
                        確定
                    </Button>
                </Box>


                {/* 確認のダイアログ */}
                <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
                    <DialogContent>
                        <p>確定してよろしいですか？</p>
                    </DialogContent>
                    <DialogActions sx={{justifyContent: 'center'}}>
                        <Button onClick={handleCloseConfirmationDialog} disabled={loading}>キャンセル</Button>
                        <Button onClick={() => updatePointToCard(userId, shopId, formValues.new_point, formValues.new_total_point)} disabled={loading}>OK</Button>
                    </DialogActions>
                </Dialog>

                {/* 成功ダイアログ */}
                <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                    <DialogContent>
                        <p>成功しました！</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSuccessDialog}>戻る</Button>
                    </DialogActions>
                </Dialog>

                {/* 失敗ダイアログ */}
                <Dialog open={openFailureDialog} onClose={handleCloseFailureDialog}>
                    <DialogContent>
                        <p>失敗しました。</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFailureDialog}>戻る</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default PointUsing;
