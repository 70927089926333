import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../components/Header';
import { Box } from '@mui/material';

export default function Faq() {
  return (
    <div>
      <Header text={'よくある質問'}/>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          >
          <Typography sx={{ textAlign: 'left' }}>ポイント付与率（〇〇円ごとに1ポイント）を変更するには？</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
            店舗情報編集画面で設定できます。<br />
            初期設定は200円ごとに1ポイントとなっています。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          >
          <Typography sx={{ textAlign: 'left' }}>ポイントカードの画像を変更するには？</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
            店舗情報編集画面で設定できます。
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          >
          <Typography sx={{ textAlign: 'left' }}>クーポンの使用方法は？</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
            まずは、発行したクーポンをお客様のアプリで表示いただきます。<br />
            表示したクーポンの「使用する」をクリックすると使用済みとなります。<br />
            クーポンの内容に応じて、お会計金額やポイント、その他サービスに関する対応は店舗側でお願いいたします。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          >
          <Typography sx={{ textAlign: 'left' }}>解約するには？</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
            ヘッダーの3本線のメニューから、決済情報を表示してください。<br />
            決済情報画面から解約が可能です。<br />
            翌月以降の退会となります。<br />
            詳細は利用規約をご確認ください。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box mt={2}>
        <a href="https://conekuta.com" target="_blank" rel="noopener noreferrer">お問合せはWebサイトのフォームから</a>
      </Box>

    </div>
  );
}